import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./layout/Header";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Faqs from "./components/Faqs";
import Services from "./components/Services";
import Rates from "./components/Rates";
import Location from "./components/Location";
import Reviews from "./components/Reviews";
import Gallery from "./components/Gallery";
import BestPrice from "./components/BestPrice";
import Footer from "./layout/Footer";
import Surroundings from "./components/surroundings";
import { useEffect } from "react";
import { Reveal } from "react-reveal";
import Rules from "./components/HouseRules";

function App() {
  const toggleVisibility = () => {
    if (window.pageYOffset > 10) {
      document.getElementById("headerid").classList.add("header-scroll");
    } else {
      document.getElementById("headerid").classList.remove("header-scroll");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div className="container-fluid p-0">
      <Header />
      <Home />
      <Reveal effect="fadeInUp">
        <AboutUs />
      </Reveal>
      <Surroundings />
      <Rules />
      <Faqs />
      <Services />
      <Rates />
      <Location />
      <Reviews />
      <Gallery />
      <BestPrice />
      <Footer />
    </div>
  );
}

export default App;
