import { ProgressBar } from "react-bootstrap";
import "./index.css";

function Index() {
  return (
    <div className="rules_container">
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row row align-items-center w-100">
          <div className="elementor-row row">
            <h2 className="rules_title">House Rules</h2>
            <span className="rules_subtitle">
              Travel inn takes special requests - add in the next step!
            </span>
          </div>

          <div className="rules_block_container">
            <div className="elementor-row row description">
              <div className="col-md-3">
                <p style={{ paddingTop: "16px" }}>
                  <span>
                    <svg
                      className="policy_name_icon"
                      height="20"
                      width="20"
                      viewBox="0 0 128 128"
                      role="presentation"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path d="M87.33 66.22c.06-.1.11-.2.16-.3.077-.125.143-.255.2-.39.054-.133.097-.27.13-.41.04-.112.073-.225.1-.34.1-.515.1-1.045 0-1.56a3.352 3.352 0 0 0-.1-.34 2.802 2.802 0 0 0-.13-.41 2.868 2.868 0 0 0-.2-.39c0-.1-.1-.2-.16-.3a4.922 4.922 0 0 0-.5-.61l-24-24a4.002 4.002 0 1 0-5.66 5.66L74.34 60H20a4 4 0 0 0 0 8h54.34L57.17 85.17a4.002 4.002 0 1 0 5.66 5.66l24-24c.183-.19.35-.394.5-.61zM92 0h-8a4 4 0 0 0 0 8h8c6.627 0 12 5.373 12 12v88c0 6.627-5.373 12-12 12h-8a4 4 0 0 0 0 8h8c11.046 0 20-8.954 20-20V20c0-11.046-8.954-20-20-20z"></path>
                    </svg>
                  </span>
                  <span className="policy_name">Check-in</span>
                </p>
              </div>
              <div className="col-md-9">
                <div className="check_in_block">
                  <span className="time_in_start">15:00</span>
                  <span className="time_in_end">00:00</span>
                  <ProgressBar className="check_in" now={40} />
                  <span
                    className="time_check_in"
                    style={{ position: "relative", left: "210px" }}
                  >
                    15:00 - 00:00
                    <span className="timebar__caption-pointer"></span>
                  </span>
                  <span className="check_in_extra">
                    Guests are required to show a photo identification and
                    credit card upon check-in
                  </span>
                </div>
              </div>
            </div>

            <div className="elementor-row row description">
              <div className="col-md-3">
                <p style={{ paddingTop: "16px" }}>
                  <span>
                    <svg
                      className="policy_name_icon"
                      height="20"
                      width="20"
                      viewBox="0 0 128 128"
                      role="presentation"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path d="M111.33 66.22c.06-.1.11-.2.16-.3.05-.1.15-.25.21-.39s.08-.27.12-.41c.039-.112.073-.225.1-.34.1-.515.1-1.045 0-1.56a3.352 3.352 0 0 0-.1-.34c0-.14-.07-.28-.12-.41-.05-.13-.14-.26-.21-.39-.07-.13-.1-.2-.16-.3a4.886 4.886 0 0 0-.5-.61l-24-24a4.002 4.002 0 1 0-5.66 5.66L98.34 60H44a4 4 0 0 0 0 8h54.34L81.17 85.17a4.002 4.002 0 1 0 5.66 5.66l24-24c.183-.19.35-.394.5-.61zM44 120h-8c-6.627 0-12-5.373-12-12V20c0-6.627 5.373-12 12-12h8a4 4 0 0 0 0-8h-8C24.954 0 16 8.954 16 20v88c0 11.046 8.954 20 20 20h8a4 4 0 0 0 0-8z"></path>
                    </svg>
                  </span>
                  <span className="policy_name">Check-out</span>
                </p>
              </div>
              <div className="col-md-9">
                <span classsName="check_out_block">
                  <span className="time_out_start">11:00</span>
                  <ProgressBar className="check_out" now={50} />
                  <span
                    className="time_check_in"
                    style={{ marginLeft: "25px" }}
                  >
                    Until 11:00 hours
                    <span className="timbar_checkout"></span>
                  </span>
                </span>
              </div>
            </div>

            <div className="elementor-row row description">
              <div className="col-md-3">
                <p className="d-flex">
                  <span>
                    <svg
                      className="policy_name_icon"
                      height="20"
                      width="20"
                      viewBox="0 0 24 24"
                      role="presentation"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path d="M14.25 15.75h-.75a.75.75 0 0 1-.75-.75v-3.75a1.5 1.5 0 0 0-1.5-1.5h-.75a.75.75 0 0 0 0 1.5h.75V15a2.25 2.25 0 0 0 2.25 2.25h.75a.75.75 0 0 0 0-1.5zM11.625 6a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25.75.75 0 0 0 0 1.5.375.375 0 1 1 0-.75.375.375 0 0 1 0 .75.75.75 0 0 0 0-1.5zM22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12z"></path>
                    </svg>
                  </span>
                  <div className="policy_name">
                    <span>Cancellation/</span>
                    <span style={{ display: "block" }}>prepayment</span>
                  </div>
                </p>
              </div>
              <div className="col-md-9">
                <span>
                  Cancellation and prepayment policies vary according to
                  accommodation type.
                </span>
              </div>
            </div>

            <div className="elementor-row row description">
              <div className="col-md-3">
                <p>
                  <span>
                    <svg
                      className="policy_name_icon"
                      height="20"
                      width="20"
                      viewBox="0 0 24 24"
                      role="presentation"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path d="M21.75 5.25a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0zm1.5 0a3.75 3.75 0 1 0-7.5 0 3.75 3.75 0 0 0 7.5 0zm-6.182 15.093l.188 1.5A.75.75 0 0 0 18 22.5h3a.75.75 0 0 0 .744-.657l.75-6-.744.657h1.5a.75.75 0 0 0 .75-.75V13.5a4.5 4.5 0 0 0-7.2-3.6.75.75 0 1 0 .9 1.2 3 3 0 0 1 4.8 2.4v2.25l.75-.75h-1.5a.75.75 0 0 0-.744.657l-.75 6L21 21h-3l.744.657-.188-1.5a.75.75 0 0 0-1.488.186zM6.75 5.25a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0zm1.5 0a3.75 3.75 0 1 0-7.5 0 3.75 3.75 0 0 0 7.5 0zM5.444 20.157l-.188 1.5L6 21H3l.744.657-.75-6A.75.75 0 0 0 2.25 15H.75l.75.75V13.5a3 3 0 0 1 4.8-2.4.75.75 0 1 0 .9-1.2A4.5 4.5 0 0 0 0 13.5v2.25c0 .414.336.75.75.75h1.5l-.744-.657.75 6A.75.75 0 0 0 3 22.5h3a.75.75 0 0 0 .744-.657l.188-1.5a.75.75 0 0 0-1.488-.186zM13.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM15 9a3 3 0 1 0-6 0 3 3 0 0 0 6 0zm-3 3a4.5 4.5 0 0 0-4.5 4.5v.75c0 .414.336.75.75.75h1.5l-.74-.627.75 4.5a.75.75 0 0 0 .74.627H12a.75.75 0 0 0 0-1.5h-1.5l.74.627-.75-4.5a.75.75 0 0 0-.74-.627h-1.5l.75.75v-.75a3 3 0 0 1 3-3 .75.75 0 0 0 0-1.5zm0 1.5a3 3 0 0 1 3 3v.75l.75-.75h-1.5a.75.75 0 0 0-.74.627l-.75 4.5.74-.627H12a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 .74-.627l.75-4.5-.74.627h1.5a.75.75 0 0 0 .75-.75v-.75A4.5 4.5 0 0 0 12 12a.75.75 0 0 0 0 1.5z"></path>
                    </svg>
                    <span className="policy_name">Children and beds</span>
                  </span>
                </p>
              </div>
              <div className="col-md-9">
                <div>
                  <div className="child_policy">
                    <h2 className="child-h2">Child policies</h2>
                    <span className="child_span">
                      Children of any age are welcome.
                    </span>
                    <span className="child_span" style={{ display: "block" }}>
                      To see correct prices and occupancy information, please
                      add the number of children in your group and their ages to
                      your search.
                    </span>
                  </div>
                  <div>
                    <h2 className="child-h2">Cot and extra bed policies</h2>
                    <span className="child_span">
                      Cots and extra beds are not available at this property.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="elementor-row row description">
              <div className="col-md-3">
                <p>
                  <span>
                    <svg
                      className="policy_name_icon"
                      height="20"
                      width="20"
                      viewBox="0 0 24 24"
                      role="presentation"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path d="M14.25 3.75a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0zm1.5 0a3.75 3.75 0 1 0-7.5 0 3.75 3.75 0 0 0 7.5 0zM18 13.5a6 6 0 0 0-12 0v2.25c0 .414.336.75.75.75H9l-.746-.675.75 7.5A.75.75 0 0 0 9.75 24h4.5a.75.75 0 0 0 .746-.675l.75-7.5L15 16.5h2.25a.75.75 0 0 0 .75-.75V13.5zm-1.5 0v2.25l.75-.75H15a.75.75 0 0 0-.746.675l-.75 7.5.746-.675h-4.5l.746.675-.75-7.5A.75.75 0 0 0 9 15H6.75l.75.75V13.5a4.5 4.5 0 1 1 9 0z"></path>
                    </svg>
                    <span className="policy_name">No age restriction</span>
                  </span>
                </p>
              </div>
              <div className="col-md-9">
                <p>
                  <span>There is no age requirement for check-in</span>
                </p>
              </div>
            </div>

            <div className="elementor-row row description">
              <div className="col-md-3">
                <p>
                  <span>
                    <svg
                      className="policy_name_icon"
                      height="20"
                      width="20"
                      viewBox="0 0 24 24"
                      role="presentation"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path d="M16.01 15a4.5 4.5 0 1 0-9 0l.75-.75a3.75 3.75 0 1 0 0 7.5 4.96 4.96 0 0 0 2.245-.59 3.277 3.277 0 0 1 3.018.005c.677.365 1.44.567 2.22.585a3.75 3.75 0 1 0 .018-7.5l.749.75zm-1.5 0c0 .414.336.75.75.75a2.25 2.25 0 0 1 0 4.5 3.435 3.435 0 0 1-1.536-.41 4.786 4.786 0 0 0-4.42-.005 3.457 3.457 0 0 1-1.562.415A2.247 2.247 0 0 1 5.51 18a2.25 2.25 0 0 1 2.25-2.25.75.75 0 0 0 .75-.75 3 3 0 1 1 6 0zm-9.75-3.75a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm1.5 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0zm3-6a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm1.5 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0zm6 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm1.5 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0zm3.75 6a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm1.5 0a3 3 0 1 0-6 0 3 3 0 0 0 6 0z"></path>
                    </svg>
                    <span className="policy_name">Pets</span>
                  </span>
                </p>
              </div>
              <div className="col-md-9">
                <p>
                  <span>Pets are allowed. Charges may be applicable.</span>
                </p>
              </div>
            </div>

            <div className="elementor-row row description">
              <div className="col-md-3">
                <p>
                  <span>
                    <svg
                      className="policy_name_icon"
                      height="20"
                      width="20"
                      viewBox="0 0 24 24"
                      role="presentation"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path d="M22.5 12v6.75a.75.75 0 0 1-.75.75H2.25a.75.75 0 0 1-.75-.75V5.25a.75.75 0 0 1 .75-.75h19.5a.75.75 0 0 1 .75.75V12zm1.5 0V5.25A2.25 2.25 0 0 0 21.75 3H2.25A2.25 2.25 0 0 0 0 5.25v13.5A2.25 2.25 0 0 0 2.25 21h19.5A2.25 2.25 0 0 0 24 18.75V12zM.75 9h22.5a.75.75 0 0 0 0-1.5H.75a.75.75 0 0 0 0 1.5zm4.5 4.5h8.25a.75.75 0 0 0 0-1.5H5.25a.75.75 0 0 0 0 1.5zm0 3h5.25a.75.75 0 0 0 0-1.5H5.25a.75.75 0 0 0 0 1.5z"></path>
                    </svg>
                    <span className="policy_name">Cash only</span>
                  </span>
                </p>
              </div>
              <div className="col-md-9">
                <p>
                  <span>This property only accepts cash payments.</span>
                </p>
                {/* <p>
                  <span>
                    Credit cards are only needed to
                    <a
                      target="_blank"
                      href="https://www.booking.com/general.en-gb.html?label=gen173nr-1DCAso7AFCGnRyYXZlbC1pbm4tbm9ydGgta2luZ3N0b3duSDNYBGhsiAEBmAEJuAEZyAEM2AED6AEBiAIBqAIDuAKK782aBsACAdICJGE0MzMxNDJlLTRkOGMtNDZiZi04NWQxLThlNGM5YzVmNTE4MdgCBOACAQ&sid=ef6fc7174349f204540eb73b2b93c0ec&amp;tmpl=doc/book_ccfaq"
                      className="booking_link"
                    >
                      guarantee your booking
                    </a>
                    .
                  </span>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
