import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-scroll";

function Index() {
  const menu = [
    {
      title: "About Us",
      link: "about",
    },
    {
      title: "FAQs",
      link: "faqs",
    },
    {
      title: "Services",
      link: "services",
    },
    {
      title: "Location",
      link: "location",
    },
    {
      title: "Reviews",
      link: "reviews",
    },
    {
      title: "Gallery",
      link: "gallery",
    },
  ];
  return (
    <>
      <Navbar
        expand="lg"
        style={{ height: 96, zIndex: 999 }}
        className="position-fixed w-100"
        id="headerid"
      >
        <Navbar.Brand href="/#">
          {/* <img
            width="320"
            height="76"
            src="https://www.shouthotels.com/wp-content/uploads/2022/07/vybe-hotel-bronx-light-logo.png"
            alt="Travel inn Logo"
          /> */}
          <h1 className="logo">Travel Inn</h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="header animated fadeIn w-100">
          <Nav className="ms-auto me-auto w-75">
            <ul className="elementor-nav-menu d-md-flex ps-0">
              {menu.length > 0 &&
                menu.map((item, index) => {
                  return (
                    <li className="header-menu-item-type-custom" key={index}>
                      <Link
                        activeClass="active"
                        className="elementor-item"
                        to={item.link}
                        spy={true}
                        smooth={true}
                        duration={500}
                        style={{ cursor: "pointer" }}
                      >
                        {item.title}
                      </Link>
                    </li>
                  );
                })}
              <li className="header-menu-item-type-custom">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={process.env.REACT_APP_BOOK_NOW}
                  className="elementor-item"
                >
                  Book Online
                </a>
              </li>
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default Index;
