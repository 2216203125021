import {
  Accordion,
  AccordionContext,
  useAccordionButton,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faCaretUp,
  faEnvelope,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { useContext } from "react";
import { Reveal } from "react-reveal";
import {
  FacebookShareButton,
  EmailShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";

function Index() {
  const socialmedia = [
    {
      title: "Facebook",
      icon: faFacebook,
      share: FacebookShareButton,
      url: "https://www.google.com/travel/hotels/s/FxUANQ93DRZmNpsz7",
    },
    {
      title: "Twitter",
      icon: faTwitter,
      share: TwitterShareButton,
      url: "https://www.google.com/travel/hotels/s/wSFa2mCoWsJr5ZE7A",
    },
    {
      title: "WhatsApp",
      icon: faWhatsapp,
      share: WhatsappShareButton,
      url: "https://www.google.com/travel/hotels/s/wSFa2mCoWsJr5ZE7A",
    },
    {
      title: "Email",
      icon: faEnvelope,
      share: EmailShareButton,
      url: "https://www.google.com/travel/hotels/s/wSFa2mCoWsJr5ZE7A",
    },
  ];

  function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <div
        style={{ color: isCurrentEventKey ? "#ad895a" : "#222222" }}
        className="accordion-link"
        onClick={decoratedOnClick}
      >
        <FontAwesomeIcon
          icon={isCurrentEventKey ? faCaretUp : faCaretRight}
          color={isCurrentEventKey ? "#ad895a" : "#222222"}
          fontSize={15}
        />
        <span className="ms-2"> {children}</span>
      </div>
    );
  }

  return (
    <div className="faq-container" id="faqs">
      <div className="elementor-container elementor-column-gap-default">
        <div className="row">
          <Reveal effect="fadeInUp">
            <div className="col-md-7 animated-slow animated fadeInUp p-3">
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default">
                      YOUR QUESTIONS ANSWERED
                    </h2>
                  </div>
                  <div className="elementor-widget-container-sub">
                    <h2 className="elementor-heading-title-sub elementor-size-default">
                      FAQ's
                    </h2>
                  </div>
                  <div className="elementor-widget-container">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <ContextAwareToggle eventKey="0">
                          What type of room can I book at Travel inn?
                        </ContextAwareToggle>
                        <Accordion.Body>
                          <p>Room options at Travel inn include:</p>
                          <ul>
                            <li>Single</li>
                            <li>Double</li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <ContextAwareToggle eventKey="1">
                          What is there to do at Travel inn?
                        </ContextAwareToggle>
                        <Accordion.Body>
                          Travel inn offers the following activities / services
                          (charges may apply):
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <ContextAwareToggle eventKey="2">
                          How much does it cost to stay at Travel inn?
                        </ContextAwareToggle>
                        <Accordion.Body>
                          The prices at Travel inn may vary depending on your
                          stay (e.g. dates you select, hotel's policy etc.). See
                          the prices by entering your dates.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <ContextAwareToggle eventKey="3">
                          Is Travel inn popular with families?
                        </ContextAwareToggle>
                        <Accordion.Body>
                          <p>
                            Yes, Travel inn is popular with guests booking
                            family stays.
                          </p>
                          {/* <ul>
                            <li>Double</li>
                          </ul> */}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <ContextAwareToggle eventKey="4">
                          What are the check-in and check-out times at Travel
                          inn?
                        </ContextAwareToggle>
                        <Accordion.Body>
                          Check-in at Travel inn is from 15:00, and check-out is
                          until 11:00.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <ContextAwareToggle eventKey="5">
                          How far is Travel inn from the centre of North
                          Kingstown?
                        </ContextAwareToggle>
                        <Accordion.Body>
                          Travel inn is 7 km from the centre of North Kingstown.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  <div
                    className="elementor-widget-container mt-4"
                    style={{ padding: "20px 15px" }}
                  >
                    <div className="elementor-button-wrapper">
                      <a
                        href={process.env.REACT_APP_ASK_QUESTION}
                        target="_blank"
                        rel="noreferrer"
                        className="question-button"
                        role="button"
                      >
                        <span className="question-button-wrapper">
                          <span className="question-button-icon">
                            <FontAwesomeIcon icon={faQuestionCircle} />
                          </span>
                          <span>Ask A Question</span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Reveal>

          <Reveal effect="fadeInUp">
            <div className="col-md-5 col-sm-12">
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div className="elementor-widget-container-sub w-100">
                    <h2 className="elementor-heading-title-sub elementor-size-default">
                      Spread The Word!
                    </h2>
                  </div>
                  <div className="elementor-widget-container">
                    {socialmedia.map((item, index) => {
                      return (
                        <item.share
                          url={item?.url}
                          className="w-80 elementor-share-btn"
                          key={index}
                        >
                          <span className="elementor-share-btn__icon">
                            <FontAwesomeIcon icon={item?.icon} fontSize={18} />
                          </span>
                          <div className="elementor-share-btn__text">
                            <span className="elementor-share-btn__title">
                              {item?.title}
                            </span>
                          </div>
                        </item.share>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  );
}

export default Index;
