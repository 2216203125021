import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import image2 from "../../assets/images/TraveliNN-2.jpg";
import image23 from "../../assets/images/TraveliNN-23.jpg";

function Index() {
  return (
    <div id="about" className="about_container">
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row row">
          <div className="col-md-6 animated-slow animated fadeInUp p-3">
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap pb-1">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title">Travel inn</h2>
                </div>
                <div className="elementor-widget-container-sub">
                  <h2 className="elementor-heading-title-sub">
                    About Travel inn
                  </h2>
                </div>
                <div className="elementor-widget-container">
                  <div className="elementor-text-editor">
                    <p>
                      Travel inn is located in North Kingstown, within 26 km of
                      Rosecliff Mansion and 27 km of The Preservation Society of
                      Newport County. The property is around 24 km from Towers
                      in Narragansett, 24 km from Pawtuxet Village and 26 km
                      from International Tennis Hall of Fame. The property is
                      situated 28 km from Vinland Estate - McAuley Hall and 28
                      km from The Breakers.
                    </p>
                    <p>
                      The hotel can conveniently provide information at the
                      reception to help guests to get around the area.
                    </p>
                    <p>
                      Chateau-sur-Mer is 27 km from Travel inn, while Marble
                      House is 28 km away. The nearest airport is T.F. Green
                      Airport, 21 km from the accommodation.
                    </p>
                  </div>
                </div>
              </div>
              <div className="elementor-widget-container mt-4">
                <div className="elementor-button-wrapper">
                  <a
                    href={process.env.REACT_APP_READ_MORE}
                    target="_blank"
                    rel="noreferrer"
                    className="question-button"
                    role="button"
                  >
                    <span className="question-button-wrapper">
                      <span className="question-button-icon">
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </span>
                      <span>READ MORE</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6 animated-slow animated fadeInUp">
            <div className="elementor-widget-wrap animated fadeInUp">
              <div className="elementor-widget elementor-widget-image elementor-widget-image-top">
                <div className="elementor-widget-container-image">
                  <div className="elementor-image">
                    <img src={image2} alt="Travel inn" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6 animated-slow animated fadeInUp">
            <div className="elementor-widget-wrap animated fadeInUp">
              <div className="elementor-widget elementor-widget-image">
                <div className="elementor-widget-container-image">
                  <div className="elementor-image">
                    <img src={image23} alt="Travel inn" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
