import { faConciergeBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.css";

function Index() {
  return (
    <section className="rate-section text-center">
      <div className="elementor-background-overlay"></div>
      <div
        className="elementor-row row w-100 align-items-center position-relative"
        style={{ minHeight: 400, zIndex: 9 }}
      >
        <div className="col-md-12">
          <div className="elementor-column-wrap elementor-element-populated">
            <div className="elementor-widget-wrap">
              <h2 className="elementor-heading-title text-white">get the</h2>
              <h4
                className="elementor-heading-heading"
                style={{ letterSpacing: 0 }}
              >
                BEST RATES GUARANTEED!
              </h4>
              <div className="elementor-button-wrapper p-3">
                <a
                  href={process.env.REACT_APP_BOOK_NOW}
                  target="_blank"
                  rel="noreferrer"
                  className="question-button text-white"
                  role="button"
                >
                  <span className="question-button-wrapper">
                    <span className="question-button-icon">
                      <FontAwesomeIcon icon={faConciergeBell} />
                    </span>
                    <span>CHECK PRICES</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Index;
