import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import "./index.css";
import { useState } from "react";
import videoimage from "../../assets/images/pexels-pixabay-413960-scaled.jpg";
function Index() {
  const [isVideoPlay, setIsVideoPlay] = useState(0);
  return (
    <>
      <section className="location-container" id="location">
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row row ">
            <div className="col-md-4">
              <div className="elementor-widget-wrap">
                <div className="elementor-widget-container">
                  <h2 className="location-heading-title">NEARBY ATTRACTIONS</h2>
                </div>
                <div className="elementor-widget-container">
                  <h2 className="location-heading-title-sub">Location</h2>
                </div>
                <div className="location-widget-container">
                  <div className="location-text-editor">
                    <p>
                      <strong>Smith s Castle</strong> – 2.4 miles
                    </p>
                    <p>
                      <strong>South County Museum</strong> – 2.9 miles
                    </p>
                    <p>
                      <strong>Warwick Rhode Island</strong> – 5.4 miles
                    </p>
                    <p>
                      <strong>Warwick Museum</strong> – 5.4 miles
                    </p>
                    <p>
                      <strong>Apponaug</strong> – 5.5 miles
                    </p>
                  </div>
                </div>
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      href={process.env.REACT_APP_VIEW_FULL_MAP}
                      target="_blank"
                      rel="noreferrer"
                      className="question-button"
                      role="button"
                    >
                      <span className="question-button-wrapper">
                        <span className="question-button-icon">
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <span>View Full Map</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              {/* <ins
                className="bookingaff"
                data-aid="2262883"
                data-target_aid="2262883"
                data-prod="map"
                data-width="100%"
                data-height="590"
                data-lang="ualng"
                data-dest_id="0"
                data-dest_type="landmark"
                data-latitude="40.902249"
                data-longitude="-73.8508887"
                data-landmark_name="Travel inn"
                data-mwhsb="0"
                data-address="6530 Post Road, North Kingstown, RI 02852, United States"
                data-bk-touched="true"
                41.6018355834269,-71.4815391318325,41.6377664165731,-71.4333868681675
              > */}
              <iframe
                src="//www.booking.com/flexiproduct.html?product=map&amp;w=100%25&amp;h=590&amp;lang=en-gb&amp;dest_id=0&amp;dest_type=landmark&amp;latitude=41.6198591&amp;longitude=-71.4575208&amp;landmark_name=Travel Inn&amp;mwhsb=0&amp;address=6530 Post Road, North Kingstown, RI 02852, United States"
                width="100%"
                height="590"
                scrolling="no"
                style={{
                  order: "none",
                  padding: 0,
                  margin: 0,
                  overflow: "hidden",
                  width: "100%",
                  height: 590,
                }}
                marginHeight="0"
                marginWidth="0"
                frameBorder="0"
                allowtransparency="true"
                id="booking_widget__2262883__1661145334744"
                data-responsive="true"
                title="locationiframeid"
              ></iframe>
              {/* </ins> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Index;
