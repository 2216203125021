import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faMapPin,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import "./index.css";
import { Link } from "react-scroll";
import { Modal } from "react-bootstrap";
import { useState } from "react";

function Index() {
  const [show, setShow] = useState(false);
  const currentYear = new Date();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <section className="footer-container">
        <div className="footer-elementor-container">
          <div className="elementor-row row w-100">
            <div className="col-md-4 p-0">
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="footer-elementor-column">
                  <div className="footer-elementor-widget-container">
                    <div className="footer-elementor-image d-flex justify-content-center">
                      {/* <img
                        width="320"
                        height="76"
                        src="https://www.shouthotels.com/wp-content/uploads/2022/07/vybe-hotel-bronx-light-logo.png"
                        className="attachment-large"
                        alt="Travel inn Logo"
                      /> */}
                      <h1 className="logo footer-logo">Travel Inn</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 p-0">
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="footer-elementor-column">
                  <div className="elementor-widget-container">
                    <nav className="elementor-nav-menu--layout-vertical">
                      <ul className="elementor-nav-menu sm-vertical">
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11649">
                          <Link
                            activeClass="active"
                            className="elementor-item elementor-item-anchor"
                            to="about"
                            spy={true}
                            smooth={true}
                            duration={500}
                            style={{ cursor: "pointer" }}
                          >
                            About Us
                          </Link>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11650">
                          <Link
                            activeClass="active"
                            className="elementor-item elementor-item-anchor"
                            to="faqs"
                            spy={true}
                            smooth={true}
                            duration={500}
                            style={{ cursor: "pointer" }}
                          >
                            FAQs
                          </Link>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11651">
                          <Link
                            activeClass="active"
                            className="elementor-item elementor-item-anchor"
                            to="services"
                            spy={true}
                            smooth={true}
                            duration={500}
                            style={{ cursor: "pointer" }}
                          >
                            Services
                          </Link>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11652">
                          <Link
                            activeClass="active"
                            className="elementor-item elementor-item-anchor"
                            to="location"
                            spy={true}
                            smooth={true}
                            duration={500}
                            style={{ cursor: "pointer" }}
                          >
                            Location
                          </Link>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11653">
                          <Link
                            activeClass="active"
                            className="elementor-item elementor-item-anchor"
                            to="reviews"
                            spy={true}
                            smooth={true}
                            duration={500}
                            style={{ cursor: "pointer" }}
                          >
                            Reviews
                          </Link>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11654">
                          <Link
                            activeClass="active"
                            className="elementor-item elementor-item-anchor"
                            to="gallery"
                            spy={true}
                            smooth={true}
                            duration={500}
                            style={{ cursor: "pointer" }}
                          >
                            Gallery
                          </Link>
                        </li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-11655">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={process.env.REACT_APP_BOOK_NOW}
                            className="elementor-item"
                          >
                            Book Online
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 p-0">
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="footer-elementor-column">
                  <div className="elementor-widget-container">
                    <ul className="elementor-icon-list-items">
                      <li className="elementor-icon-list-item">
                        <a href="tel:+17189940760">
                          <span className="elementor-icon-list-icon">
                            <FontAwesomeIcon
                              icon={faPhone}
                              fontSize={30}
                              fontWeight={900}
                              color="#fff"
                            />
                          </span>
                          <span className="elementor-icon-list-text">
                            +1 781-600-9068
                          </span>
                        </a>
                      </li>
                      <li className="elementor-icon-list-item">
                        <span className="elementor-icon-list-icon">
                          <FontAwesomeIcon
                            icon={faMapPin}
                            fontSize={30}
                            fontWeight={900}
                            color="#fff"
                          />
                        </span>
                        <span className="elementor-icon-list-text">
                          6530 Post Road, North Kingstown, RI 02852, United
                          States
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-dark-custom p-4">
        <div className="elementor-container-width">
          <div className="row w-100 align-items-center">
            <div className="col-md-3">
              <div className="sub-footer">
                Copyright ©{currentYear.getFullYear()}{" "}
                <a className="text-white" href="https://www.travelinhotel.com">
                  <u>Travel inn</u>
                </a>
              </div>
            </div>
            <div className="col-md-6 text-center m-0">
              <div className="elementor-widget-wrap">
                <div className="elementor-widget-container">
                  <div className="elementor-icon-box-content">
                    <h3 className="sub-footer-icon-box-title">
                      <span> Disclaimer: </span>
                    </h3>
                    <div className="d-flex justify-content-cneter">
                      <div className="elementor-icon-box-icon">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          fontSize={30}
                          fontWeight={900}
                          color="#fff"
                        />
                      </div>
                      <p className="sub-footer-icon-box-description">
                        This is the official hotel website. Our purpose is to
                        provide all information in one place about the property.
                      </p>
                    </div>

                    <div className="elementor-widget-container">
                      <div className="elementor-text-editor elementor-clearfix">
                        <div
                          className="sub-footer text-white text-decoration-underline"
                          onClick={handleShow}
                        >
                          Click here to suggest an edit
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="sub-footer">
                Developed by{" "}
                <a
                  className="text-white"
                  href="https://www.Vibrantprintshop.com"
                  target="_blank"
                >
                  <u>Vibrantprintshop.com</u>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Please type your suggested edit below: </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <textarea
                id="form-field-message"
                className="form-control"
                rows="4"
                placeholder="Please write a description of your suggested edit here."
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-send w-100" type="button">
            Send
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Index;
