import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faCar,
  faInfoCircle,
  faLanguage,
  faLock,
  faWheelchair,
  faWifi,
} from "@fortawesome/free-solid-svg-icons";
import { Reveal } from "react-reveal";
import Wifi from "../../assets/images/wifi-icon-background-white.png";
import lock from "../../assets/images/padlock-icon-background-white.png";
import car from "../../assets/images/car-services-icon.png";
import wheelchair from "../../assets/images/other-services-bell-background-icon.png";
import language from "../../assets/images/languages-spoken-services.png";
import infocircle from "../../assets/images/info-icon-background-white.png";

function Index() {
  const services = [
    {
      name: "Free WiFi",
      icon: faWifi,
      backgoundurl: Wifi,
      data: ["WiFi is available in public areas and is free ofcharge."],
      other: "",
    },
    {
      name: "Safety & Security",
      icon: faLock,
      backgoundurl: lock,
      data: [
        "Fire extinguishers",
        "CCTV outside property",
        "CCTV in common areas",
        "Smoke alarms",
        "Key card access",
        "24-hour security",
      ],
      other: "",
    },
    {
      name: "Parking",
      icon: faCar,
      backgoundurl: car,
      data: [],
      other:
        "Free private parking is possible on site (reservation is not needed)",
    },
    {
      name: "Accessibility",
      icon: faWheelchair,
      backgoundurl: wheelchair,
      data: [
        "Lower bathroom sink",
        "Higher-level toilet",
        "Toilet with grab rails",
        "Wheelchair accessible",
      ],
      other: "",
    },
    {
      name: "Languages Spoken",
      icon: faLanguage,
      backgoundurl: language,
      data: ["English", "Hindi", "Urdu", "Gujarati"],
      other: "",
    },
    {
      name: "General",
      icon: faInfoCircle,
      backgoundurl: infocircle,
      data: [
        "Air conditioning",
        // "Heating",
        // "Soundproof rooms",
        // "Lift",
        // "Facilities for disabled guests",
        "Non-smoking rooms",
      ],
      other: "",
    },
  ];
  return (
    <div className="services-container" id="services">
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row row w-100">
          <div className="animated-slow animated fadeInUp">
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div className="elementor-widget-container">
                  <h2 className="elementor-heading-title elementor-size-default">
                    A SUMMARY OF
                  </h2>
                </div>
                <div className="elementor-widget-container">
                  <h4 className="elementor-heading-heading elementor-size-default">
                    SERVICES
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row w-100">
            {services.length > 0 &&
              services.map((item, i) => {
                return (
                  <Reveal effect="fadeInUp" key={i}>
                    <div className="col-md-4 animated fadeIn">
                      <div className="services-about">
                        <div
                          className="services-background-overlay"
                          style={{
                            backgroundImage: "url(" + item?.backgoundurl + ")",
                          }}
                        ></div>
                        <div className="services-elementor-widget-wrap">
                          <div className="services-elementor-widget-container">
                            <div className="elementor-icon-wrapper">
                              <div className="elementor-icon">
                                <FontAwesomeIcon
                                  icon={item?.icon}
                                  fontSize={37}
                                  color={"#ad895a"}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="elementor-widget-container">
                            <h4 className="service-elementor-heading-title-sub">
                              {item?.name}
                            </h4>
                          </div>
                          <div className="elementor-widget-container">
                            <div className="service-elementor-text-editor elementor-clearfix">
                              {item?.other && <p>{item?.other}</p>}
                              <ul>
                                {item?.data.map((data, j) => {
                                  return <li key={j}>{data}</li>;
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Reveal>
                );
              })}
          </div>
        </div>
      </div>
      <section className="view-all-services">
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row w-100 d-flex justify-content-center">
            <div className="elementor-button-wrapper">
              <a
                href={process.env.REACT_APP_VIEW_ALL_SERVICES}
                target="_blank"
                rel="noreferrer"
                className="question-button"
                role="button"
              >
                <span className="question-button-wrapper">
                  <span className="question-button-icon">
                    <FontAwesomeIcon icon={faBed} />
                  </span>
                  <span>VIEW ALL SERVICES</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Index;
