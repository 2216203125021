import { faComments } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProgressBar } from "react-bootstrap";
import CountUp from "react-countup";
import "./index.css";

function Index() {
  return (
    <div className="reviews-container" id="reviews">
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row row align-items-center w-100">
          <div className="elementor-widget-container">
            <h2 className="reviews-heading-title">
              BASED ON 100% REAL GUEST EXPERIENCES
            </h2>
          </div>
          <div className="elementor-widget-container">
            <h4 className="reviews-heading-title-sub">Reviews</h4>
          </div>

          <div className="col-md-4 animated-slow animated fadeInUp">
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div className="elementor-widget-container">
                  <div className="elementor-counter text-center">
                    <div className="elementor-counter-number-wrapper">
                      <span className="elementor-counter-number-prefix"></span>
                      <span className="elementor-counter-number">
                        <CountUp
                          start={0}
                          end={8.7}
                          duration={2.75}
                          separator=" "
                          decimals={1}
                        ></CountUp>
                      </span>
                      <span className="elementor-counter-number-suffix"></span>
                    </div>
                    <div className="elementor-counter-title">
                      Overall Score Based On 3 Reviews
                      <br /> As Of 27/08/2022
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 animated-slow animated fadeInUp">
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div className="reviews-widget-container">
                  <span className="reviews-title">Staff </span>
                  <ProgressBar now={92} label={`${9.2}`} />
                </div>
                <div className="reviews-widget-container">
                  <span className="reviews-title">Comfort </span>
                  <ProgressBar now={92} label={`${9.2}`} />
                </div>
                <div className="reviews-widget-container">
                  <span className="reviews-title">Value for money </span>
                  <ProgressBar now={100} label={`${10}`} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 animated-slow animated fadeInUp">
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div className="reviews-widget-container">
                  <span className="reviews-title">Facilities </span>
                  <ProgressBar now={83} label={`${8.3}`} />
                </div>
                <div className="reviews-widget-container">
                  <span className="reviews-title">Cleanliness </span>
                  <ProgressBar now={92} label={`${9.2}`} />
                </div>
                <div className="reviews-widget-container">
                  <span className="reviews-title">Location </span>
                  <ProgressBar now={100} label={`${100}`} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="view-all-services">
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row w-100 d-flex justify-content-center">
            <div className="elementor-button-wrapper">
              <a
                href={process.env.REACT_APP_READ_ALL_REVIEWS}
                target="_blank"
                rel="noreferrer"
                className="question-button"
                role="button"
              >
                <span className="question-button-wrapper">
                  <span className="question-button-icon">
                    <FontAwesomeIcon icon={faComments} />
                  </span>
                  <span>READ ALL REVIEWS</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Index;
