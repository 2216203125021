import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonWalking } from "@fortawesome/free-solid-svg-icons";
// import { faPersonSkiLift } from "@fortawesome/fontawesome-svg-core";
import "./index.css";

const description_nearby = [
  { description: "Sawmill Pond Park", distance: "1 km" },
  { description: "Fry Brook Recreation Park", distance: "2.3 km" },
  { description: "Carcieri Field", distance: "3 km" },
  { description: "Scalloptown Park", distance: "3.4 km" },
  { description: "Eldredge Field", distance: "4.1 km" },
  { description: "Hayward Park", distance: "4.4 km" },
  { description: "Laurel Wood", distance: "4.4 km" },
  { description: "Frenchtown Park", distance: "4.5 km" },
  { description: "Briggs-Boesch Farm", distance: "4.5 km" },
  { description: "Fry Family Nature Preserve", distance: "4.6 km" },
];

const description_attractions = [
  { description: "J L Curran Park", distance: "16 km" },
  { description: "Museum of Newport History", distance: "19 km" },
  { description: "Castle Hill Light", distance: "19 km" },
  { description: "Roger Williams Park Zoo", distance: "19 km" },
  { description: "Culinary Archives & Museum", distance: "19 km" },
  { description: "Newport Art Museum", distance: "19 km" },
  { description: "Samuel Whitehorne House", distance: "19 km" },
  { description: "Museum of Yachting", distance: "20 km" },
  { description: "International Tennis Hall of Fame", distance: "20 km" },
  { description: "Kingscote Newport Mansion", distance: "20 km" },
];
const description_skylifts = [
  { description: "Mary Chair Lift", distance: "13 km" },
  { description: "Yawgoo Ropetow", distance: "13 km" },
  { description: "Conan Chair Lift", distance: "13 km" },
];

const description_beatches = [
  { description: "Blue Beach", distance: "4.6 km" },
  { description: "Compass Rose Beach", distance: "5 km" },
  { description: "Buttonwoods Beach", distance: "9 km" },
  { description: "Oakland Beach", distance: "9 km" },
  { description: "Sunset Beach", distance: "9 km" },
];

const description_airports = [
  { description: "T.F. Green Airport", distance: "12 km" },
  { description: "Newport State Airport", distance: "18 km" },
  { description: "North Central State Airport", distance: "34 km" },
];

const description_cafes = [
  { description: "Restaurant  Dells Lemonade", distance: "100 m" },
  { description: "Restaurant  Walt's Roast Beef", distance: "350 m" },
  { description: "Restaurant  KFC", distance: "650 m" },
  { description: "Cafe/bar  Felicia's Coffee", distance: "2.3 km" },
  { description: "Cafe/bar  MaryLou's", distance: "3.1 km" },
  { description: "Cafe/bar  Starbucks", distance: "4.1 km" },
];

const description_beauty = [
  { description: "Lake mishnock", distance: "12.9 km" },
  { description: "Big River", distance: "12.9 km" },
  { description: "Wolf rock trail", distance: "19.3 km" },
  { description: "Newport Beach", distance: "20.9 km" },
];

function Index() {
  return (
    <div className="surrounding_container">
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row row surrounding-title">
          Hotel Surroundings
        </div>
        <div className="elementor-row row">
          <div className="col-md-4">
            <div className="elementor-row row block_section_container ">
              <div className="elementor-row row d-flex">
                <span className="text_title p-0">
                  <FontAwesomeIcon
                    icon={faPersonWalking}
                    fontSize={20}
                    className="icon_surroundings"
                  />
                  What's nearby
                </span>
              </div>
              <div className="elementor-row row d-flex">
                <ul className="ul_list">
                  {description_nearby?.length > 0 &&
                    description_nearby.map((value, index) => {
                      return (
                        <li key={index} className="list_item">
                          <div className="description_distance">
                            <div className="">{value?.description}</div>
                            <div>{value?.distance}</div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="elementor-row row block_section_container ">
              <div className="elementor-row row d-flex">
                <span className="text_title p-0">
                  <FontAwesomeIcon
                    icon={faPersonWalking}
                    fontSize={20}
                    className="icon_surroundings"
                  />
                  Restaurants & cafes
                </span>
              </div>
              <div className="elementor-row row d-flex">
                <ul className="ul_list">
                  {description_cafes?.length > 0 &&
                    description_cafes.map((value, index) => {
                      return (
                        <li key={index} className="list_item">
                          <div className="description_distance">
                            <div>{value?.description}</div>
                            <div>{value?.distance}</div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="elementor-row row block_section_container ">
              <div className="elementor-row row">
                <span className="text_title p-0">
                  <FontAwesomeIcon
                    icon={faPersonWalking}
                    fontSize={20}
                    className="icon_surroundings"
                  />
                  Top attractions
                </span>
                <div className="elementor-row row d-flex">
                  <ul className="ul_list">
                    {description_attractions?.length > 0 &&
                      description_attractions.map((value, index) => {
                        return (
                          <li key={index} className="list_item">
                            <div className="description_distance">
                              <div>{value?.description}</div>
                              <div>{value?.distance}</div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="elementor-row row block_section_container">
              <div className="elementor-row row d-flex">
                <span className="text_title p-0">
                  <FontAwesomeIcon
                    icon={faPersonWalking}
                    fontSize={20}
                    className="icon_surroundings"
                  />
                  Natural beauty
                </span>
              </div>
              <div className="elementor-row row d-flex list">
                <ul className="ul_list">
                  {description_beauty?.length > 0 &&
                    description_beauty.map((value, index) => {
                      return (
                        <li key={index} className="list_item">
                          <div className="description_distance">
                            <div>{value?.description}</div>
                            <div>{value?.distance}</div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="elementor-row row block_section_container ">
              <div className="elementor-row row d-flex">
                <span className="text_title p-0">
                  <FontAwesomeIcon
                    icon={faPersonWalking}
                    fontSize={20}
                    className="icon_surroundings"
                  />
                  Sky lifts
                </span>
              </div>
              <div className="elementor-row row d-flex">
                <ul className="ul_list">
                  {description_skylifts?.length > 0 &&
                    description_skylifts.map((value, index) => {
                      return (
                        <li key={index} className="list_item">
                          <div className="description_distance">
                            <div>{value?.description}</div>
                            <div>{value?.distance}</div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>

            <div className="elementor-row row block_section_container ">
              <div className="elementor-row row d-flex">
                <span className="text_title p-0">
                  <FontAwesomeIcon
                    icon={faPersonWalking}
                    fontSize={20}
                    className="icon_surroundings"
                  />
                  Beaches in the neighbourhood
                </span>
              </div>
              <div className="elementor-row row d-flex">
                <ul className="ul_list">
                  {description_beatches?.length > 0 &&
                    description_beatches.map((value, index) => {
                      return (
                        <li key={index} className="list_item">
                          <div className="description_distance">
                            <div>{value?.description}</div>
                            <div>{value?.distance}</div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>

            <div className="elementor-row row block_section_container ">
              <div className="elementor-row row d-flex">
                <span className="text_title p-0">
                  <FontAwesomeIcon
                    icon={faPersonWalking}
                    fontSize={20}
                    className="icon_surroundings"
                  />
                  Closest airports
                </span>
              </div>
              <div className="elementor-row row d-flex">
                <ul className="ul_list">
                  {description_airports?.length > 0 &&
                    description_airports.map((value, index) => {
                      return (
                        <li key={index} className="list_item">
                          <div className="description_distance">
                            <div>{value?.description}</div>
                            <div>{value?.distance}</div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          <div className="disclaimer mt-0">
            * All distances are measured in straight lines. Actual travel
            distances may vary.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
