import { Navigation, EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import image77 from "../../assets/images/TraveliNN-77.jpg";
import image78 from "../../assets/images/TraveliNN-78.jpg";
import image34 from "../../assets/images/TraveliNN-34.jpg";

function Index() {
  const images = [image78, image77, image34];

  return (
    <Swiper
      modules={[Autoplay, EffectFade, Navigation]}
      navigation={{
        prevEl: ".elementor-swiper-button-prev",
        nextEl: ".elementor-swiper-button-next",
      }}
      effect="fade"
      slidesPerView={1}
      slidesPerGroup={1}
      speed={10}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      loop={true}
      parallax={true}
      className="myswiper"
      grabCursor
      observeParents
      observer
      onSlideChangeTransitionStart={() => {
        document
          .querySelectorAll(".swiper-slide-inner")
          .forEach((element) => element.classList.add("d-none"));
      }}
      onSlideChangeTransitionEnd={() => {
        document
          .querySelectorAll(".swiper-slide-inner")
          .forEach((element) => element.classList.remove("d-none"));
      }}
    >
      {images.length > 0 &&
        images.map((item, index) => {
          return (
            <SwiperSlide className="swipersslide" key={index}>
              <div
                className="swiper-slide-bg"
                style={{
                  backgroundImage: "url(" + item + ")",
                }}
              ></div>
              <div className="elementor-background-overlay"></div>
              <div className="swiper-slide-inner">
                <div className="swiper-slide-contents animated fadeInUp">
                  <div className="elementor-slide-heading">Travel inn</div>
                  <div className="elementor-slide-description">
                    3-Star Hotel In The North Kingstown
                  </div>

                  <i className="fa-solid fa-chevron-left"></i>
                  <a
                    href={process.env.REACT_APP_BOOK_NOW}
                    target="_blank"
                    rel="noreferrer"
                    className="elementor-button elementor-slide-button elementor-size-sm"
                  >
                    BOOK NOW
                  </a>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      <div
        className="elementor-swiper-button elementor-swiper-button-prev"
        tabIndex="0"
        role="button"
        aria-label="Previous slide"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <div
        className="elementor-swiper-button elementor-swiper-button-next"
        tabIndex="1"
        role="button"
        aria-label="Next slide"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </Swiper>
  );
}

export default Index;
