import { Gallery, Item } from "react-photoswipe-gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/free-solid-svg-icons";
import "photoswipe/dist/photoswipe.css";
import "./index.css";
import image34 from "../../assets/images/TraveliNN-34.jpg";
import image8 from "../../assets/images/TraveliNN-8.jpg";
import image14 from "../../assets/images/TraveliNN-14.jpg";
import image46 from "../../assets/images/TraveliNN-46.jpg";
import image2 from "../../assets/images/TraveliNN-2.jpg";
import image23 from "../../assets/images/TraveliNN-23.jpg";

const fullscreenSVG =
  '<svg aria-hidden="true" class="pswp__icn" viewBox="0 0 32 32" width="32" height="32"><use class="pswp__icn-shadow" xlink:href="#pswp__icn-fullscreen-exit"/><use class="pswp__icn-shadow" xlink:href="#pswp__icn-fullscreen-request"/><path d="M8 8v6.047h2.834v-3.213h3.213V8h-3.213zm9.953 0v2.834h3.213v3.213H24V8h-2.834zM8 17.953V24h6.047v-2.834h-3.213v-3.213zm13.166 0v3.213h-3.213V24H24v-6.047z" id="pswp__icn-fullscreen-request"/><path d="M11.213 8v3.213H8v2.834h6.047V8zm6.74 0v6.047H24v-2.834h-3.213V8zM8 17.953v2.834h3.213V24h2.834v-6.047h-2.834zm9.953 0V24h2.834v-3.213H24v-2.834h-3.213z" id="pswp__icn-fullscreen-exit" style="display:none"/></svg>';

function Index() {
  const fullscreenAPI = getFullscreenAPI();
  const uiElements = [
    {
      name: "custom-button",
      ariaLabel: "Custom button",
      order: 7,
      isButton: true,
      html: fullscreenSVG,
      onInit: (el, pswpInstance) => {},
      onClick: (e, el, pswpInstance) => {
        toggleFullscreen();
      },
      onClose: () => {
        if (fullscreenAPI && fullscreenAPI.isFullscreen()) {
          fullscreenAPI.exit();
        }
      },
    },
  ];
  function getFullscreenAPI() {
    let api;
    let enterFS;
    let exitFS;
    let elementFS;
    let changeEvent;
    let errorEvent;
    if (document.documentElement.requestFullscreen) {
      enterFS = "requestFullscreen";
      exitFS = "exitFullscreen";
      elementFS = "fullscreenElement";
      changeEvent = "fullscreenchange";
      errorEvent = "fullscreenerror";
    } else if (document.documentElement.webkitRequestFullscreen) {
      enterFS = "webkitRequestFullscreen";
      exitFS = "webkitExitFullscreen";
      elementFS = "webkitFullscreenElement";
      changeEvent = "webkitfullscreenchange";
      errorEvent = "webkitfullscreenerror";
    }
    if (enterFS) {
      api = {
        request: function (el) {
          if (enterFS === "webkitRequestFullscreen") {
            el[enterFS](Element.ALLOW_KEYBOARD_INPUT);
          } else {
            el[enterFS]();
          }
        },
        exit: function () {
          return document[exitFS]();
        },
        isFullscreen: function () {
          return document[elementFS];
        },
        change: changeEvent,
        error: errorEvent,
      };
    }
    return api;
  }

  function toggleFullscreen() {
    if (fullscreenAPI) {
      if (fullscreenAPI.isFullscreen()) {
        // Exit full-screen mode
        fullscreenAPI.exit();
        // Toggle "Exit" and "Enter" full-screen SVG icon display
        setTimeout(function () {
          document.getElementById("pswp__icn-fullscreen-exit").style.display =
            "none";
          document.getElementById(
            "pswp__icn-fullscreen-request"
          ).style.display = "inline";
        }, 300);
      } else {
        // Enter full-screen mode
        fullscreenAPI.request(document.querySelector(`.pswp`));
        // Toggle "Exit" and "Enter" full-screen SVG icon display
        setTimeout(function () {
          document.getElementById("pswp__icn-fullscreen-exit").style.display =
            "inline";
          document.getElementById(
            "pswp__icn-fullscreen-request"
          ).style.display = "none";
        }, 300);
      }
    }
  }

  const images = [image2, image34, image8, image14, image23, image46];

  return (
    <>
      <div className="services-container" id="gallery">
        <div className="elementor-container elementor-column-gap-default">
          <div className="elementor-row row w-100">
            <div className="animated-slow animated fadeInUp" id="services">
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div className="elementor-widget-container">
                    <h2 className="elementor-heading-title elementor-size-default">
                      SEE FOR YOURSELF
                    </h2>
                  </div>
                  <div className="elementor-widget-container">
                    <h4 className="elementor-heading-heading elementor-size-default">
                      Gallery
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row w-100">
              <Gallery uiElements={uiElements} withDownloadButton withCaption>
                {images.length > 0 &&
                  images.map((item, index) => {
                    return (
                      <Item
                        key={index}
                        original={item}
                        thumbnail={item}
                        width="1024"
                        height="768"
                        caption="Travel Inn"
                      >
                        {({ ref, open }) => (
                          <div
                            className="col-md-4 animated fadeIn"
                            style={{ cursor: "pointer" }}
                            ref={ref}
                            onClick={open}
                          >
                            <div className="gallery-about">
                              <div
                                className="elementor-gallery-item__overlay"
                                style={{
                                  backgroundImage: "url(" + item + ")",
                                }}
                              ></div>
                              <div className="gallery-elementor-widget-wrap"></div>
                            </div>
                          </div>
                        )}
                      </Item>
                    );
                  })}
              </Gallery>
            </div>
          </div>
        </div>
        <section className="view-all-services">
          <div className="elementor-container elementor-column-gap-default">
            <div className="elementor-row w-100 d-flex justify-content-center">
              <div className="elementor-button-wrapper">
                <a
                  href={process.env.REACT_APP_VIEW_ALL_IMAGES}
                  target="_blank"
                  rel="noreferrer"
                  className="question-button"
                  role="button"
                >
                  <span className="question-button-wrapper">
                    <span className="question-button-icon">
                      <FontAwesomeIcon icon={faImages} />
                    </span>
                    <span>VIEW ALL IMAGES</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Index;
